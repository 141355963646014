import { Controller } from "stimulus";
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  connect(){

    $(this.element).modal();
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
  }

  next(event) {
    if (event.detail.success) {
      Turbo.visit(window.location.href)
    }
  }


  disconnect() {
    $(this.element).modal('hide');
  }
}
