$(document).on("turbo:load", function(){
  var dates = getDisabledDates(); //
  $('#lock_datepicker').datepicker({
    format: 'yyyy-mm-dd',
    todayBtn: true,
    language: "pl",
    today: 'Dzisiaj',
    beforeShowDay: function(date) {
      if (dates.includes(date.toLocaleDateString('fr-CA')))
      {
        return {classes: "highlight"};
      }
      return [false, '', ''];
    }
  }).datepicker("setDate", new Date($('#picket-date').text())).on('changeDate', function (e) {
    window.location.href ='/calendars?picket_date=' + e.date.toLocaleDateString('fr-CA');
  });

  function getDisabledDates(){
    var value= $.ajax({
      url: '/occupied_dates/get_dates.json',
      async: false
    }).responseText;
    return  JSON.parse(value);
  }
});

