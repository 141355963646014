import { Controller } from "stimulus";
//import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
	static targets = [ "output"]

	setread() {
		const id = this.element.dataset.commentId
		const url = "/comments/" + id +"/mark_read"
		fetch(url)
		this.outputTarget.outerHTML = ''
	}
}
