// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
//require("@rails/activestorage").start()
require("channels");
require("chart.js");
require("@hotwired/turbo-rails");

require("controllers");

import $ from "jquery";
global.$ = jQuery;

require("datatables.net-bs4");
require("custom");
require("@fancyapps/fancybox");

import "bootstrap";
import "moment";
import "../src/application.scss";
import "jquery-validation";
import Inputmask from "inputmask";
import "bootstrap-datepicker";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.pl.js";
import "bootstrap-fileinput";

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
});

jQuery.extend(jQuery.validator.messages, {
  required: "To pole jest wymagane.",
  remote: "Please fix this field.",
  email: "Proszę podać prawidłowy adres email.",
  url: "Please enter a valid URL.",
  date: "Please enter a valid date.",
  dateISO: "Please enter a valid date (ISO).",
  number: "Please enter a valid number.",
  digits: "Please enter only digits.",
  creditcard: "Please enter a valid credit card number.",
  equalTo: "Please enter the same value again.",
  accept: "Please enter a value with a valid extension.",
  maxlength: jQuery.validator.format(
    "Please enter no more than {0} characters."
  ),
  minlength: jQuery.validator.format("Please enter at least {0} characters."),
  rangelength: jQuery.validator.format(
    "Please enter a value between {0} and {1} characters long."
  ),
  range: jQuery.validator.format("Please enter a value between {0} and {1}."),
  max: jQuery.validator.format(
    "Please enter a value less than or equal to {0}."
  ),
  min: jQuery.validator.format(
    "Please enter a value greater than or equal to {0}."
  ),
});

$(document).on("turbo:load", function () {
  "use strict";
  $(function () {
    var names = [];
    $(".file-upload-browse").on("click", function () {
      var file = $(this)
        .parent()
        .parent()
        .parent()
        .find(".file-upload-default");
      console.log(file);
      file.trigger("click");
    });
    $(".file-upload-default").on("change", function () {
      for (var i = 0; i < $("#order_images").get(0).files.length; i++) {
        names.push($("#order_images").get(0).files[i].name);
      }
      $(this).parent().find(".form-control").val(names);
    });
  });
});
window.change_names = function() {
  var names = [];
  for (var i = 0; i < $('#comment_images').get(0).files.length; i++) {
    names.push($('#comment_images').get(0).files[i].name);
  }
  $('.file-upload-info').val(names);
}

