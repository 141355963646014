$(function(){
  $("#wizard").steps({
    headerTag: "h2",
    bodyTag: "section",
    transitionEffect: "fade",
    enableAllSteps: true,
    transitionEffectSpeed: 500,
    labels: {
      finish: "Wyślij &nbsp; <i class='mdi mdi-check'></i>",
      next: "Następny  &nbsp; <i class='mdi mdi-arrow-right'></i>",
      previous: "<i class='mdi mdi-arrow-left'></i> &nbsp; Poprzedni &nbsp;"
    },
    onStepChanging: function (event, currentIndex, newIndex)
    {
      var form = $(this);
      form.validate().settings.ignore = ":disabled,:hidden";
      return form.valid();
    },
    onFinished: function (event, currentIndex) {
      $("#wizard").submit();
    }
  });
  $('.wizard > .steps li a').click(function(){
    $(this).parent().addClass('checked');
    $(this).parent().prevAll().addClass('checked');
    $(this).parent().nextAll().removeClass('checked');
  });
  // Custome Jquery Step Button
  $('.forward').click(function(){
    $("#wizard").steps('next');
  })
  $('.backward').click(function(){
    $("#wizard").steps('previous');
  })
  // Select Dropdown
  $('html').click(function() {
    $('.select .dropdown').hide(); 
  });
  $('.select').click(function(event){
    event.stopPropagation();
  });
  $('.select .select-control').click(function(){
    $(this).parent().next().toggle();
  })    
  $('.select .dropdown li').click(function(){
    $(this).parent().toggle();
    var text = $(this).attr('rel');
    $(this).parent().prev().find('div').text(text);
  })
});

$(function() {
  Inputmask({"mask":"999-999-999"}).mask($('#order_phone'));
});

$(function() {
	function getDisabledDates(){
		var value= $.ajax({
			url: '/occupied_dates/get_dates.json',
			async: false
		}).responseText;
		return  JSON.parse(value);
	}



	$('#order_deadline').datepicker({
		format: 'yyyy-mm-dd',
		timePicker: true,
		language: "pl",
		startDate: new  Date(),
		datesDisabled: getDisabledDates()
	});
});
