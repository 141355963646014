require('./misc.js');
require('./jquery.steps.js');
require('./chart_oll.js');
require('./dashboard.js');
require('./form_main.js');
require('./hoverable-collapse.js');
require('./datetimeset.js');
require('./canvas.js');
require('./calendar_mobile_datepicker.js');



